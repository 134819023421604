<template>
  <div class="section">
    <img
      class="icon"
      :src="icon"
      v-if="icon"
    >
    <span
      class="title"
      v-if="title"
    >
      {{ title }}
    </span>
    <span
      class="brief"
      v-if="brief"
    >
      {{ brief }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'SectionHeader',
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    brief: {
      type: String,
      default: ''
    }
  },
};
</script>

<style lang="less" scoped>
.section {
  display: flex;

  .icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    color: #333;
    line-height: 28px;
    margin-right: 10px;
  }

  .brief {
    font-size: 26px;
    font-weight: 600;
    color: #999;
    line-height: 28px;
  }
}
</style>