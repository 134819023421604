<template>
  <div class="empty">
    <div class="image-box">
      <img
        class="image"
        src="@/assets/images/pic-noresult@2x.png"
      >
    </div>
    <div class="text">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    text: {
      type: String,
      default: '暂无结果',
    }
  }
};
</script>

<style lang="less" scoped>
.empty {
  margin-top: 120px;

  .image-box {
    display: flex;
    justify-content: center;

    .image {
      width: 185px;
      height: 165px;
      flex-shrink: 0;
    }
  }


  .text {
    font-size: 30px;
    font-weight: 400;
    color: #333;
    line-height: 44px;
    margin-top: 30px;
    text-align: center;
  }
}
</style>