<template>
  <div class="bound-sessions-page">
    <div v-if="groupSessions && groupSessions.length > 0">
      <section-header
        :icon="require('@/assets/images/m-icon-title@2x.png')"
        title="已绑定群聊"
      />
      <div class="group-chat-container">
        <cell-group-chat
          class="chat-item"
          v-for="item in groupSessions"
          :data="item"
          :key="item.id"
        />
      </div>
    </div>

    <div v-if="userSessions && userSessions.length > 0">
      <section-header
        :icon="require('@/assets/images/m-icon-title@2x.png')"
        title="已绑定客户"
      />

      <div class="user-chat-container">
        <cell-user-chat
          class="chat-item"
          v-for="item in userSessions"
          :data="item"
          :key="item.id"
        />
      </div>
    </div>

    <empty
      text="暂无绑定"
      v-if="(!groupSessions || groupSessions.length === 0) && (!userSessions || userSessions.length === 0)"
    />
  </div>
</template>

<script>
import CellGroupChat from '@/components/CellGroupChat';
import CellUserChat from '@/components/CellUserChat';
import SectionHeader from '@/components/SectionHeader';
import Empty from '@/components/Empty';


export default {
  name: 'BoundSessions',
  components: {
    Empty,
    CellUserChat,
    CellGroupChat,
    SectionHeader,
  },
  props: {
    sessions: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      groupSessions: [],
      userSessions: [],
    };
  },
  watch: {
    sessions(newValue, oldValue) {
      this.setupSessions();
    }
  },
  created() {
    this.setupSessions();
  },
  methods: {
    setupSessions() {
      const groupSessions = [];
      const userSessions = [];
      this.sessions.forEach((x) => {
        if (x.type === 'external_user') {
          userSessions.push(x);
        } else {
          groupSessions.push(x);
        }
      });
      this.groupSessions = groupSessions;
      this.userSessions = userSessions;
    }
  }
};
</script>

<style lang="less" scoped>
.bound-sessions-page {
  padding: 40px 30px;

  .group-chat-container {
    margin-bottom: 50px;
  }

  .user-chat-header {
    margin-top: 50px;
  }

  .user-chat-container {
  }

  .chat-item {
    margin-top: 30px;
  }
}
</style>