<template>
  <div class="cell-group-chat">
    <img
      class="avatar"
      src="@/assets/images/icon-group@2x.png"
    >
    <span class="name">{{ data.name }}</span>
    <span
      class="option"
      @click="openChat"
    >
      打开聊天
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CellGroupChat',
  props: {
    data:{
      type: Object,
      default() {
        return null;
      }
    }
  },
  methods: {
    openChat() {
      this.openExternalGroupChat({ chatId: this.data.group_chat_id });
    },
    ...mapActions({
      openExternalGroupChat:
        'wecom/openExternalGroupChat',
    }),
  }
};
</script>

<style lang="less" scoped>
.cell-group-chat {
  background: #F9F9F9;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  display: flex;
  align-items: center;
  padding: 30px 30px 30px 20px;

  .avatar {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
  }

  .name {
    font-size: 28px;
    font-weight: 400;
    color: #333;
    line-height: 38px;
    margin-left: 30px;
    margin-right: 50px;
    width: 100%;
  }

  .option {
    font-size: 24px;
    font-weight: 400;
    color: #0095FF;
    line-height: 34px;;
    flex-shrink: 0;
    cursor: pointer;
  }
}
</style>