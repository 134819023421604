<template>
  <div class="tab">
    <div
      class="tab-item"
      :class="activeIndex === index ? 'active': ''"
      v-for="(item, index) in items"
      :key="index+''"
      @click="change(item, index)"
    >
      <span>{{ item }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    change(item, index) {
      this.activeIndex = index;
      this.$emit('change', item, index);
    }
  }
};
</script>

<style lang="less" scoped>
.tab {
  display: flex;

  .tab-item {
    background: #F7F7F7;
    border-radius: 8px 8px 0 0;
    padding: 22px 18px 20px;
    font-size: 28px;
    font-weight: 600;
    color: #333;
    line-height: 28px;
    cursor: pointer;

    &:nth-child(n+2) {
      margin-left: 10px;
    }
  }


  .active {
    background: #0095FF;
    color: #FFF;
  }
}
</style>